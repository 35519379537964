<template>
  <v-app class="mx-auth mx-auth-center">

    <img class="mx-auth-logo" src="/assets/images/logo-primary.png" alt="logo">
    <span class="mx-auth-version">{{ options.version }}</span>

    <v-card width="500" elevation="3" class="ma-auto px-7 py-11">
      <v-card-title class="mt-n3">
        <h1 class="display-1">{{ $t('auth.form.password.forgot.title') }}</h1>
        <h6 style="font-weight: 400; margin-left: 3px;" class="mb-1">{{ $t('auth.form.password.forgot.subtitle') }}</h6>
      </v-card-title>
      <v-card-text>
        <v-form ref="signUpForm" v-model="formValidity">
          <v-text-field
              v-model="form.email"
              type="email"
              :label="$t('auth.form.login.label.email')"
              :rules="emailRules"
              required
              outlined
              hide-details
          ></v-text-field>
        </v-form>
        <v-alert
            v-if="error"
            border="left"
            color="red"
            type="error"
        >{{ $t('auth.form.login.error') }}</v-alert>
      </v-card-text>
      <v-card-actions class="mt-3 pl-4">
        <v-btn
            :loading="preloader"
            large color="primary"
            class="px-8 mr-6"
            @click="login"
            :disabled="!formValidity"
        >
          {{ $t('auth.form.password.forgot.btn.send') }}
        </v-btn>
        <router-link :to="{name: 'login'}">{{ $t('auth.form.password.forgot.btn.login') }}</router-link>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      form: {
        email: '',
      },
      error: false,
      preloader: false,
      emailRules: [
          value => !!value || 'Email is required.',
      ],
      passwordRules: [
        value => !!value || 'Email is required.',
      ],
      formValidity: false
    }
  },
  methods: {
    resetValidation() {
      this.$refs.signUpForm.resetValidation()
    },
    resetForm() {
      this.$refs.signUpForm.reset()
    },

    login() {
      this.preloader = true
      this.error = false
      this.$store.dispatch('passwordForgot', this.form).then(() => {
        this.$router.replace({
          name: 'password.verify'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.error = true;
            break;
        }
        this.preloader = false
      })
    }
  }
}
</script>